const __request = require(`./__request/__request_contentType_json`)

// http://192.168.43.143:8765/swagger-ui.html#/t-us-notice-controller/removeByIdUsingPOST
const request = (params) => {
    if (!params) params = {}

    const id = params.id || null

    let pParameter = {
        method: 'post',
        urlSuffix: '/consumer/consumer/notice/removeById',
        data: {
            id,
        }
    }
    return __request(pParameter).then(data => {
        if (data.code === '000000') {
            return data
        }
    }).catch((err) => {
        console.log('接口 consumer_notice_removeById 异常')
        throw(err)
    })
}
export default request